/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link, navigate } from 'gatsby';
import { useDispatch } from 'react-redux';

import Section from '../components/section';

import GraphQLForm from '../components/forms/GraphQLForm';
import TextInput from '../components/forms/TextInput';

import { user as userSchema } from '@bit/morleytatro.main.kongrads-schemas';

const query = `
  mutation($input: UserInput!) {
    register(input: $input) {
      id
      is_admin
    }
  }
`;

export default function Register() {
  const dispatch = useDispatch();

  function handleSuccess(data) {
    dispatch({
      type: 'SET_USER',
      user: data.register
    });

    const redirectUrl = localStorage.getItem('redirectUrl');
    localStorage.removeItem('redirectUrl');
    navigate(redirectUrl || '/app/profile');
  }

  return (
    <Section sx={{
      variant: 'centeredInner'
    }}>
      <div>
        <h1 sx={{ variant: 'text.heading' }}>Sign Up</h1>
        <GraphQLForm
          query={query}
          defaultValues={{
            email: '',
            password: ''
          }}
          validationSchema={userSchema}
          onSuccess={handleSuccess}
        >
          <TextInput
            name="email"
            label="Email"
            type="email"
          />
          <TextInput
            name="password"
            label="Password"
            type="password"
          />
        </GraphQLForm>
      <p>Already have an account? <Link to="/app/login">Log in here</Link></p>
      </div>
    </Section>
  )
}