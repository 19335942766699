import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';

export default function ProtectedRoute({
  component: Component,
  ...rest
}) {
  const user = useSelector(state => state.user);

  if(!user) return <Redirect to="/app/login" noThrow/>;

  return <Component {...rest}/>;
}