import React, { useEffect, useState } from 'react';
import { useLocation, navigate } from '@reach/router';
import { useMutation } from 'graphql-hooks';
import { Spinner } from 'theme-ui';

import PageWrap from '../components/PageWrap';

const query = `
  mutation($code: String!, $state: String!) {
    processStripeReturn(code: $code, state: $state) {
      message
    }
  }
`;

export default function StripeReturn() {
  const location = useLocation();
  const [redirectError, setRedirectError] = useState(null);
  const [processQueryParams] = useMutation(query);

  useEffect(() => {
    const { code, error_description, state } = location
      .search
      .slice(1)
      .split('&')
      .reduce((acc, item) => {
        const [key, val] = item.split('=');
  
        return {
          ...acc,
          [key]: val
        };
      }, {});
    
    if(typeof error_description !== 'undefined') {
      setRedirectError(error_description);
    } else if(typeof code === 'undefined' || typeof state === 'undefined') {
      navigate('/app/settings');
    } else {
      processQueryParams({ variables: { code, state } })
        .then(() => navigate('/app/settings'));
    }

  }, []); // eslint-disable-line

  const inner = redirectError
    ? <p style={{ color: 'red' }}>{redirectError}</p>
    : <Spinner/>

  return (
    <PageWrap title="Stripe Return">
      {inner}
    </PageWrap>
  );
}