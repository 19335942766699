import React from 'react';
import { Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'theme-ui';

import Layout from '../components/layout';
import ProtectedRoute from '../components/ProtectedRoute';

import protectedRoutes from '../lib/protected-routes';

import Login from './Login';
import Signup from './Signup';
import NotFound from './NotFound';
import StripeReturn from './StripeReturn';

import { useQueryCustom } from '../lib/hooks';

const query = `
  query {
    getCurrentUser {
      id
      is_admin
    }
  }
`;

export default function App() {
  const loaded = useSelector(state => state.loaded);
  const dispatch = useDispatch();
  useQueryCustom(query, {
    onSuccess: data => onLoaded(data.getCurrentUser),
    onError: () => onLoaded(null)
  });

  function onLoaded(user) {
    dispatch({
      type: 'SET_USER',
      user
    });
    dispatch({ type: 'SET_LOADED' });
  }

  // useEffect(() => {
  //   if(!loaded && !loading) {
  //     onLoaded(data.getCurrentUser);
  //   }
  // }, [loaded, loading]); // eslint-disable-line

  let inner = <Spinner/>;

  if(loaded) {
    inner = (
      <Router basepath="/app">
        <Login path="login"/>
        <Signup path="signup"/>
        {protectedRoutes.map((route, i) => (
          <ProtectedRoute key={i} {...route}/>
        ))}
        <ProtectedRoute
          path="stripe-return"
          component={StripeReturn}
        />
        <NotFound default/>
      </Router>
    );
  }

  return (
    <Layout>
      {inner}
    </Layout>
  );
}